import {Container } from "react-bootstrap";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import dev_pic from '../img/dev_dude.jpeg';

export function Introduction(): JSX.Element { 


    return(
        <section className="bg-dark text-light p-5 p-lg-0 pt-lg-5 text-center text-sm-start">
            <Container>
            <div className="d-sm-flex align-items-center justify-content-between">
                <div>
                    <h1><span className="text-warning">BG </span> Web Development </h1>
                    <p className="lead my-4">
                    Its just a place in the web where my ideas come true
                    </p>
                    <button
                    className="btn btn-primary btn-lg"
                    data-bs-toggle="modal"
                    data-bs-target="#enroll"
                    >
                    Learn about me!
                    </button>
                </div>
                <img
                    className="img-fluid w-25 d-none d-sm-block"
                    src={dev_pic}
                    alt=""
                />
                </div>
            </Container>
        </section>     
    )
  

}