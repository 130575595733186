import { createAsyncThunk } from "@reduxjs/toolkit"

//interface cheetSheetInput{
//    hanoilvl: string
//}

export const getHanoiCheatSheet = createAsyncThunk(
    "hanoitower/getHanoiCheatSheet",
    async (arg: number, thunkAPI) => {
        console.log("Küld")
        const response = await fetch('https://bgdevelopment.hu/:3001/api/hanoisteps/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify({hanoilvl: arg})
    
        })
        await console.log(response.json())
        return await response.json()
        //return ['cool']
    }
)