import styled from "styled-components"


export const CVFillSpace = styled.div`
display: flex;
flex-flow: column;
height: 100%;`

export const LayerDropButton = styled.button`
display: flex;
flex-direction: row;
width:100%;
justify-content:center;
padding: 0;
border: none;
background: none;


`