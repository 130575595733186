import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getHanoiCheatSheet } from "./HanoitowerAction"




export interface HanoiState  {cheatSheet: Array<string>}
    


export const initialState: HanoiState = {
    cheatSheet: ['Solve1','Solve2']
}

const HanoitowerSlice = createSlice({
    name: "hanoitower",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(getHanoiCheatSheet.fulfilled, (state, action: PayloadAction<HanoiState>) => {
            //state.schedules = action.payload.schedules
            console.log('action.payload: ' + action.payload.cheatSheet)
            state.cheatSheet = action.payload.cheatSheet
        })
       // builder.addCase(scheduleCreate.fulfilled, (state, action) => {
       //     state.schedules.push(action.payload)
       // })
       
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = HanoitowerSlice