import { createAsyncThunk } from "@reduxjs/toolkit"


export const sendURL = createAsyncThunk(
    "webcrawler/sendURL",
    async (arg: string, thunkAPI) => {
        console.log("Küld")
        const response = await fetch('http://bgdevelopment.hu/api/webscraper/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify({url: arg})
    
        })

        return await response.json()
        //return ['cool']
    }
)

export const sendRealEstateURL = createAsyncThunk(
    "webcrawler/sendRealEstateURL",
    async (arg: string, thunkAPI) => {
        const response = await fetch('http://bgdevelopment.hu/api/webscrapersingle/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify({realEstateURL: arg})
    
        })

        return await response.json()
        //return ['cool']
    }
)

