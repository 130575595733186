import styled from "styled-components"

export const BatchLoaderContainer = styled.div`
margin-top: 5px;
display: flex;
flex-direction: row;
`

export const ConcatInputContainer = styled.div`
display: flex;
flex-direction: column;
`


export const ConcatInput = styled.input`
height: 200px;
margin: 2px;
`

export const ApplyChanges = styled.button`
border: 0px solid black;
border-radius: 5px;
margin: 2px;
`