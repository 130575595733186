import styled from "styled-components"
import { Col, Container, Row } from 'react-bootstrap'

 interface colorThemesIF {
    type: string,
    colors:{
        buildingBlock: string,
        hanoiPole: string
    }
}

const colorThemes: colorThemesIF = 
{
    type: 'summerfun',
    colors:{
        buildingBlock: 'blue',
        hanoiPole: 'red'
    }
}

//Tower Pole Control
const towerLevel: number = 2;
const poleLength: number = 250 - ((towerLevel+1) * 8)

interface HanoiTowerPoleProps{
    height: number
}

export const HanoiTowerPole = styled.div<HanoiTowerPoleProps>`
border-left: 6px solid brown;
align-self: center;
height: ${(props) => props.height.toString()}px;
`
//----------------------

//z-index: -1;
//position: absolute;

interface HanoiBuildingBlockProps{
    width: number
}


export const HanoiBuildingBlock = styled.div<HanoiBuildingBlockProps>`
    background: ${colorThemes.colors.buildingBlock};
    cursor: pointer;
    width: ${props => props.width}px;
    min-width: ${props => props.width}px;
    max-width: 250px
    display: flex;
    align-items: center;
    min-height: 25px;
    max-height: 50px;
    border-radius: 25px;
    border-color: red;
    border-style: solid;
    border-width: thin;
    align-self: center;
    
`
//position: absolute;

export const TowerHolderOutside = styled(Col)`
min-height:350px;
`

export const Droppables = styled.div`
    display: flex;
    > div {
        flex: 1;
    }
`