import { combineReducers, configureStore } from '@reduxjs/toolkit'
//import { createStore } from 'redux'
import  {reducer as HanoitowerSlice} from './slices/HanoitowerSlice'
import  {reducer as GuiSlice} from './slices/GuiSlice'
import  {reducer as CVGridSlice} from './slices/CVGridSlice'
import  {reducer as WebCrawlerSlice} from './slices/WebCrawlerSlice'
import  {reducer as RealEstateSlice} from './slices/RealEstateSlice'
import  {reducer as KafkaChatSlice} from './slices/KafkaChatSlice'
import  {reducer as GoogleMapRoutesSlice} from './slices/GoogleMapRoutesSlice'

//export const store = createStore(HanoitowerReducer)


export const rootReducer = combineReducers({
  hanoiTower: HanoitowerSlice,
  gui: GuiSlice,
  cvGrid: CVGridSlice,
  webCrawler: WebCrawlerSlice,
  realEstate: RealEstateSlice,
  kafkaChat: KafkaChatSlice,
  googleMapRoutes: GoogleMapRoutesSlice
})


export const store = configureStore({
    reducer: rootReducer
   })
   
export type RootState = ReturnType<typeof rootReducer>