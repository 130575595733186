import React from 'react'
import {Button} from 'grommet'
import { Add } from 'grommet-icons'
interface exampleJSXProps{
    uniquekey:string
}

export const ExampleJSX = ({uniquekey}: exampleJSXProps): JSX.Element =>{

    return(
        <Button color="dark-1" key={uniquekey} primary  icon={ <Add color="accent-1" /> } label="Add" onClick={() => {}} />
    )

}