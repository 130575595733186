import { CustNavbar } from "components/CustNavbar"
import { BatchLoaderContainer, ConcatInputContainer, ConcatInput, ApplyChanges } from "components/layout/WebCrawlerComponents"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { setNavbarTitleByURL } from "slices/GuiAction"
import { sendURL, sendRealEstateURL } from "slices/WebCrawlerAction"

function WebCrawler():JSX.Element {
    const dispatch = useDispatch()
    const [URL, setURL] = useState("")
    const [RealEstateURL, setRealEstateURL] = useState("")
    useEffect(() => {
        dispatch(setNavbarTitleByURL(
            {
                isSubPage: true,
                URLPath: window.location.pathname
            }))
      }, [])
    return(
        <>
        <CustNavbar/>
        <Row>
            <Col>
            <div className="container">
                <input type="text" onChange={e => setURL(e.target.value)} placeholder="Enter URL" name="urlscrape" value={URL} required />
            </div>
            <div className="container">
                <button onClick={() => dispatch(sendURL(URL))}>Save Page</button> 
            </div>
            </Col>

        </Row>
        <BatchLoaderContainer>
            <ConcatInputContainer>
                <ConcatInput onChange={e => setRealEstateURL(e.target.value)} value={RealEstateURL}/>
                <ApplyChanges onClick={() => dispatch(sendRealEstateURL(RealEstateURL))}>Upload</ApplyChanges>
            </ConcatInputContainer>

        </BatchLoaderContainer>
        </>
    )
    
}


export default WebCrawler