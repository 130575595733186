import { Container } from "react-bootstrap";
import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { GrGamepad } from "react-icons/gr";
import { IoBulbOutline, IoLogoAppleAr } from "react-icons/io5";
import axios from "axios";
//import { FunnyJoke } from "services/funnyJoke";




export function ImgBoxes(): JSX.Element { 

   const [funJoke,setFunJoke] = useState<String>("")
   console.log(funJoke)

    const FunnyJoke = () => {
      const config = {
        headers:{Accept: 'application/json'},
    }

    axios.get("https://icanhazdadjoke.com/",config).then(res => {
      setFunJoke(res.data.joke)
    })
     
    }
    useEffect(() => {
      FunnyJoke()
    }, [])
    
    return(
        <section className="p-5">
        <Container>
          <div className="row text-center g-4">
            <div className="col-md">
              <div className="card bg-dark text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <IoBulbOutline/>
                  </div>
                  <h3 className="card-title mb-3">Serious staff</h3>
                  <p className="card-text">
                    CV creator now, and we will see later
                  </p>
                  <a href="/" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card bg-secondary text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <GrGamepad/>
                  </div>
                  <h3 className="card-title mb-3">Games</h3>
                  <p className="card-text">
                    Hanoi Game cheatsheet and Hanoi Game itself
                  </p>
                  <a href="/" className="btn btn-dark">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card bg-dark text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <IoLogoAppleAr/>
                  </div>
                  <h3 className="card-title mb-3">Funny Joke</h3>
                  <p className="card-text">
                    {funJoke}
                  </p>
                  <button  className="btn btn-primary" onClick={async () => {
                    FunnyJoke()}}>Hit me!</button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
  
    )
  

}