import { Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { SelectBox } from './form/SelectBox';
import { ApplyButton, ButtonHolder,MinusCircleButtonLogo,PlusCircleButtonLogo } from './layout/FormComponents';
import CVCreatorGridProps from 'interfaces/CVCreator/cvCreatorGridProps';
import rowLayout from 'interfaces/CVCreator/rowLayout';
import { colLayout } from 'interfaces/CVCreator/colLayout';
import { RootState } from 'store';
import { setCVGridDimensions } from 'slices/CVGridAction';



export function CVCreatirGridSetter(): JSX.Element {
//decrement()
//increment()

    const {gridLayout, children} = useSelector((state: RootState) => state.cvGrid)
    const [numberOfRows, setNumberOfRows] = useState<number>(gridLayout.numberOfRows)
    const [selectedRow, setSelectedRow] = useState<number>(gridLayout.numberOfRows)
    const [numberOfCols, setNumberOfCols] = useState<number>(gridLayout.rowLayouts[gridLayout.numberOfRows -1].numberOfCols)
    const [selectedCol, setSelectedCol] = useState<number>(numberOfCols)
    const [colWidth, setColWidth] = useState<number>(5)
    const [rowHeight, setRowHeight] = useState<number>(40)
    const dispatch = useDispatch() 

    const increment = () => {
        const newState = numberOfRows + 1
        setNumberOfRows(newState)

    }
    
    const decrement = () => {
        const newState = numberOfRows -1
        setNumberOfRows(newState)
    }

    const applyChanges = () => {
        
        let rowLayouts: Array<rowLayout> = gridLayout.rowLayouts
        const rowId = selectedRow -1
        const colId = selectedCol -1
        let colLayouts: Array<colLayout> | undefined = []
        
        //Fix pipa adott col width-hez és akkor a többi változásakor az nem változhat



        //const indexOfRow = gridLayout.rowLayouts.indexOf({rowId:rowId, numberOfCols: numberOfCols})
        const indexOfRow = gridLayout.rowLayouts.findIndex(x => x.rowId ===rowId)
        rowLayouts = rowLayouts.map((x) => {return x})
        if (indexOfRow >-1)
        {
            //ColLayout
            const indexOfCol = gridLayout.rowLayouts[indexOfRow].colLayouts?.indexOf({colId: colId})
            colLayouts = gridLayout.rowLayouts[indexOfRow].colLayouts
            if(colLayouts != undefined) {
                colLayouts = colLayouts.map((x) => {return x})
            } 
            if ( indexOfCol != undefined && indexOfCol > -1 &&  colLayouts != undefined)
            {
                
                colLayouts.splice(indexOfCol,1,{colId:colId,colProperty: colWidth.toString()})

            }
            else if(colLayouts != undefined) {
                
                colLayouts.push({colId:colId,colProperty: colWidth.toString()})
            }

            
            //RowLayout 
            rowLayouts.splice(indexOfRow,1,{rowId:rowId,numberOfCols:numberOfCols,colLayouts:colLayouts})
            
        }
        else{
            colLayouts.push({colId:colId,colProperty: colWidth.toString()})
            rowLayouts.push({rowId: rowId, numberOfCols: numberOfCols, colLayouts:colLayouts})

            }
     
        
        const changedState: CVCreatorGridProps =
            {
                gridLayout:{
                    numberOfRows: numberOfRows,
                    rowLayouts: rowLayouts
                    

                    },
                    children: children
            }
        dispatch(setCVGridDimensions(changedState))

    }

    return(
       <Container> 
            <Row className="d-flex">
                    <Col className="d-flex flex-column justify-content-start ">      
                        <Row className="d-flex flex-column align-self-center">
                            <h3>Number of Rows</h3>'
                        </Row>   
                        <Row>
                            <Col>
                                <ButtonHolder type="button" onClick={() => {decrement()}} className="btn btn-default btn-number" data-type="minus" >
                                    <MinusCircleButtonLogo/>
                                </ButtonHolder>
                            </Col>
                            <Col>
                                <input type="text" name="numberOfRows" onChange={e => {setNumberOfRows(+e.target.value)}}
                                 className="form-control input-number" value= {numberOfRows.toString()}  min="1" max="10"/>
                            </Col>
                            <Col>
                                <ButtonHolder type="button" onClick={() => {increment()}} className="btn btn-default btn-number" data-type="plus">
                                    <PlusCircleButtonLogo/>
                                </ButtonHolder>
                            </Col>                                        
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-start">
                                <p>SRow</p>
                                <SelectBox numberOfElements = {numberOfRows} onChange={(e) => {setSelectedRow(e.target.value)}}/>
                            </Col>
                            <Col className="d-flex justify-content-start">
                                <p>Cols</p>
                                <input type="text" name="numberOfCols" onChange={(e) => {setNumberOfCols(+e.target.value)}}
                                    className="form-control input-number" value= {numberOfCols.toString()}  min="1" max="10"/>
                            </Col>
                            <Col className="d-flex justify-content-start">
                                <p>SCol</p>
                                <SelectBox numberOfElements = {numberOfCols} onChange={(e) => {setSelectedCol(e.target.value)}}/>
                            </Col>
                            <Col className="d-flex justify-content-start">
                                <p>CW</p>
                                <input type="text" name="columnWidth" onChange={(e) => {setColWidth(+e.target.value)}}
                                    className="form-control input-number" value= {colWidth.toString()} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <ApplyButton onClick={()=>{applyChanges()}}>Apply</ApplyButton>
                            </Col>
                            <Col className="d-flex justify-content-start">
                            <p>RH</p>
                                <input type="text" name="rowHeight" onChange={(e) => {setRowHeight(+e.target.value)}}
                                    className="form-control input-number" value= {rowHeight.toString()} />
                            </Col>

                        </Row>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h1>DONE'</h1>
                    </Col>
                </Row>
       </Container>
    )
}