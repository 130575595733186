import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sendURL, sendRealEstateURL } from "./WebCrawlerAction"




export interface WebCrawlerState  {url: string}
    


export const initialState: WebCrawlerState = {
    url: ''
}

const WebCrawlerSlice = createSlice({
    name: "webcrawler/sendURL",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(sendURL.fulfilled, (state, action: PayloadAction<WebCrawlerState>) => {
            //state.schedules = action.payload.schedules
            console.log('action.payload: ' + action.payload.url)
            state.url = action.payload.url
        })    
        builder.addCase(sendRealEstateURL.fulfilled, (state, action) => {
            //state.schedules = action.payload.schedules
            console.log('Success')
        })        
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = WebCrawlerSlice