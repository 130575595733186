import styled from "styled-components";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

export const SelectDropDown = styled.select`
border-style: solid;
border-radius: 20px;`

export const ButtonHolder = styled.button`
width:80%;
height:80%;
background: transparent;
border: none !important;
`

export const PlusCircleButtonLogo = styled(AiOutlinePlusCircle)`
width:100%;
height:100%;
color:#6FFFB0;
`
//border-style: solid;
//border-radius: 50px;
//background-color:black;

export const MinusCircleButtonLogo = styled(AiOutlineMinusCircle)`
width:100%;
height:100%;
color:red;
`

export const ApplyButton = styled.button`
border-style: solid;
border-radius: 20px;
color:#00BFFF;
background-color: #9932CC;
`