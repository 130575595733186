import { useEffect, useState } from 'react'
import { CustNavbar } from '../components/CustNavbar'
import { useDispatch } from 'react-redux'
import { setNavbarTitleByURL } from '../slices/GuiAction'
import { Col, Container, Row } from 'react-bootstrap'
import { HanoiBuildingBlock, HanoiTowerPole, TowerHolderOutside } from '../components/layout/HanoiGameComponents'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
//import { useMatch } from "@reach/router"

function HanoiGame (): JSX.Element{

    interface lvlList {
        lvlId: number,
        lvlLength: number
    }

    interface towerHeights {
        height: number
    }

    const defaultGameComplete: boolean = false
    const [gameComplete,setGameComplete] = useState<boolean>(defaultGameComplete)

    const defaultTowerLvl: string = "3"
    const [towerlvl, setTowerlvl] = useState<string>(defaultTowerLvl)


    const defaultTowerHeights: Array<towerHeights> = [{height: 250 - (+defaultTowerLvl *25)},{height:250},{height:250}]
    const [towerPoleHeights,setTowerPoleHeights] = useState<Array<towerHeights>>(defaultTowerHeights)

    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(setNavbarTitleByURL(
          {
              isSubPage: true,
              URLPath: window.location.pathname
          }))    
    }, [])
    useEffect(() => {
        generateTower(+towerlvl)    
      }, [towerlvl])


    // Functions for the input form

    const increment = () => {
        if(+towerlvl < 7)
        {
            const newLvl: string = (+towerlvl + 1).toString()
            setTowerlvl(newLvl)
        }
        else{
            const newLvl: string = "7"
            setTowerlvl(newLvl)
        }
        
    }

    const decrement = () => {
        if(+towerlvl > 1)
        {
            const newLvl: string = (+towerlvl - 1).toString()
            setTowerlvl(newLvl)
            
        }
        else{
            

        }
        

    }

    // Generate the list
    const generateTower = (numberOfLvls: number) => {
        const listOfLvls: Array<lvlList> = []
        const startWidth: number = 45
        for (let i = 0; i < numberOfLvls; i++) {
            listOfLvls.push({lvlId: i, lvlLength: (startWidth + i*15) })
          }
        const allTowers: Array<Array<lvlList>> = [listOfLvls,[],[]]
        setlistOfLvlsOfTowers(allTowers)
        const aTowerHeight: number = 250 - (allTowers[0].length * 25) 
        const bTowerHeight: number = 250 - (allTowers[1].length * 25)
        const cTowerHeight: number = 250 - (allTowers[2].length * 25)
        const newTowerHeights: Array<towerHeights> = [{height: aTowerHeight},{height: bTowerHeight},{height: cTowerHeight}]
        console.log(newTowerHeights)
        setTowerPoleHeights(newTowerHeights)
    }


    //const listOfLvlsOfTowers: Array<Array<lvlList>> = [[{lvlId: 0,lvlLength: 95},{lvlId: 1,lvlLength: 110},{lvlId: 2,lvlLength: 125}],[],[]]
    const tempLvl: Array<Array<lvlList>> = [[],[],[]]
    const [Lvls, setlistOfLvlsOfTowers] = useState(tempLvl);
    const listOfLvlsOfTowers: Array<Array<lvlList>> = Lvls

    const updateTower = (tower: Array<Array<lvlList>>, e: DropResult,sourceTowerIndex: number,destTowerIndex: number) =>{

        console.log('In0')
        console.log('Dest: ' + e.destination?.index)
        console.log('Source: ' + e.source?.index)

        if(e.destination?.index != null && e.source?.index != null)
        
        {
            console.log('In1')
            if(e.destination.droppableId === e.source.droppableId)
            {

                alert('Cant switch lvls inside the same tower!!')

                // Minta kód -- Hanoi Game szabályai miatt nem lehet azonos oszlopon belül mozgatni!
                //const [reorderedItem] = tower[sourceTowerIndex].splice(e.source.index, 1);
                //tower[sourceTowerIndex].splice(e.destination.index, 0, reorderedItem);
                //console.log(e.destination)
                //setlistOfLvlsOfTowers(tower);
                //console.log('Same')

            }
            else if(destTowerIndex != null)
            {
                const grabbedItemLength: number = tower[sourceTowerIndex][e.source.index].lvlLength
                const destTowerTopLvlLength: number | null = (tower[destTowerIndex].length > 0) ? tower[destTowerIndex][0].lvlLength : null

                if ((destTowerTopLvlLength == null) || grabbedItemLength < destTowerTopLvlLength)
                {
                    // Minta kód -- Hanoi Game szabályai miatt csak a legfelső helyre kerülhet
                    // const destIndex = e.destination.index
                    const destIndex = 0
                    const [reorderedItem] = tower[sourceTowerIndex].splice(e.source.index, 1);
                    // console.log(reorderedItem)
                    // console.log(tower[sourceTowerIndex])
                    // console.log(tower[destTowerIndex])
                    tower[destTowerIndex].splice(destIndex, 0, reorderedItem);
                    //  console.log(tower[destTowerIndex])
                    setlistOfLvlsOfTowers(tower);
                    


                    const aTowerHeight: number = 250 - (tower[0].length * 25) 
                    const bTowerHeight: number = 250 - (tower[1].length * 25)
                    const cTowerHeight: number = 250 - (tower[2].length * 25)
                    const newTowerHeights: Array<towerHeights> = [{height: aTowerHeight},{height: bTowerHeight},{height: cTowerHeight}]
                    console.log(newTowerHeights)
                    setTowerPoleHeights(newTowerHeights)

                    //  console.log(listOfLvlsOfTowers)
                    const isGameComplete: boolean = tower[2].length == +towerlvl
                    setGameComplete(isGameComplete)
                }
            }
        }
    }


    return(
        
        <><CustNavbar />
        <section className="m-5">
            <Container>
                <Row className="d-flex">
                    <Col className="d-flex justify-content-start">         
                        <Row>
                            <Col>
                                <button type="button" onClick={() => {decrement()}} className="btn btn-default btn-number" data-type="minus">
                                    <AiOutlineMinusCircle style={{'width':'60%','height':'60%'}}/>
                                </button>
                            </Col>
                            <Col>
                                <input type="text" name="quant[1]" onChange={e => setTowerlvl(e.target.value)} className="form-control input-number" value= {towerlvl}  min="1" max="10"/></Col>
                            <Col>
                                <button type="button" onClick={() => {increment()}} className="btn btn-default btn-number" data-type="plus">
                                    <AiOutlinePlusCircle style={{'width':'60%','height':'60%'}}/>
                                </button>
                            </Col>                                        
                        </Row>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h1>{gameComplete ? 'DONE' : 'COMPLETE THE GAME'}</h1>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <DragDropContext onDragEnd={(e) => {
                    if (!e.destination) return;


                    if(e.source.droppableId === 'ATower' && e.destination.droppableId === 'BTower')
                    {
                        updateTower(Lvls,e,0,1)
                        console.log('AB')
                    }
                    else if(e.source.droppableId === 'ATower' && e.destination.droppableId === 'CTower')
                    {
                        updateTower(Lvls,e,0,2)
                        console.log('AC')
                    }
                    else if(e.source.droppableId === 'BTower' && e.destination.droppableId === 'ATower')
                    {
                        console.log('BA')
                        updateTower(Lvls,e,1,0)
                    }
                    else if(e.source.droppableId === 'BTower' && e.destination.droppableId === 'CTower')
                    {
                        console.log('BC')
                        updateTower(Lvls,e,1,2)
                    }
                    else if(e.source.droppableId === 'CTower' && e.destination.droppableId === 'ATower')
                    {
                        console.log('CA')
                        updateTower(Lvls,e,2,0)
                    }
                    else if(e.source.droppableId === 'CTower' && e.destination.droppableId === 'BTower')
                    {
                        console.log('CB')
                        updateTower(Lvls,e,2,1)
                    }

                }}>
                    <Row>
                        

                                    
                        <Droppable droppableId="ATower" >
                        
                        {(provided) => (
                            <TowerHolderOutside {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-column justify-content-center align-items-end border border-primary rounded-1 m-auto p-2" >
                            
                            <HanoiTowerPole height={towerPoleHeights[0].height} className='justify-content-start'/>
                               
                                {listOfLvlsOfTowers[0].map((levels, index) => {  
                                    if (index == 0){
                                        return (   
                                            <Draggable key={levels.lvlId.toString()} draggableId={provided.droppableProps['data-rbd-droppable-id'] + levels.lvlId.toString()} index={index}>
                                                {(provided) => (
                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} ref={provided?.innerRef}  {...provided?.draggableProps} {...provided?.dragHandleProps} className=" justify-content-end" width={levels.lvlLength} />
                                                )}
                                            </Draggable>         
                                        )
                                        
                                    }
                                    else
                                    {
                                        return (   

                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} key={levels.lvlId.toString()} className=" justify-content-end" width={levels.lvlLength} />      
                                        )

                                    }
                              
                                
                                
                                })}

                            
                            {provided.placeholder}
                          
                            </TowerHolderOutside> )}
                        </Droppable>
                        
                        

                        
                        <Droppable droppableId="BTower" >
                        
                        {(provided) => (
                            <TowerHolderOutside {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-column justify-content-center align-items-end border border-primary rounded-1 m-auto p-2" >
                            
                            <HanoiTowerPole height={towerPoleHeights[1].height} className='justify-content-start'/>
                               
                                {listOfLvlsOfTowers[1].map((levels, index) => {  
                                    if (index == 0){
                                        return (   
                                            <Draggable key={levels.lvlId.toString()} draggableId={provided.droppableProps['data-rbd-droppable-id'] + levels.lvlId.toString()} index={index}>
                                                {(provided) => (
                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} ref={provided?.innerRef}  {...provided?.draggableProps} {...provided?.dragHandleProps} className=" justify-content-end" width={levels.lvlLength} />
                                                )}
                                            </Draggable>         
                                        )
                                        
                                    }
                                    else
                                    {
                                        return (   

                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} key={levels.lvlId.toString()} className=" justify-content-end" width={levels.lvlLength} />      
                                        )

                                    }
                              
                                
                                
                                })}

                            
                            {provided.placeholder}
                          
                            </TowerHolderOutside> )}
                        </Droppable>


                        <Droppable droppableId="CTower" >
                        
                        {(provided) => (
                            <TowerHolderOutside {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-column justify-content-center align-items-end border border-primary rounded-1 m-auto p-2" >
                            
                            <HanoiTowerPole height={towerPoleHeights[2].height} className='justify-content-start'/>
                               
                                {listOfLvlsOfTowers[2].map((levels, index) => {  
                                    if (index == 0){
                                        return (   
                                            <Draggable key={levels.lvlId.toString()} draggableId={provided.droppableProps['data-rbd-droppable-id'] + levels.lvlId.toString()} index={index}>
                                                {(provided) => (
                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} ref={provided?.innerRef}  {...provided?.draggableProps} {...provided?.dragHandleProps} className=" justify-content-end" width={levels.lvlLength} />
                                                )}
                                            </Draggable>         
                                        )
                                        
                                    }
                                    else
                                    {
                                        return (   

                                                <HanoiBuildingBlock  id={levels.lvlId.toString()} key={levels.lvlId.toString()} className=" justify-content-end" width={levels.lvlLength} />      
                                        )

                                    }
                              
                                
                                
                                })}

                            
                            {provided.placeholder}
                          
                            </TowerHolderOutside> )}
                        </Droppable>
                    </Row>
                </DragDropContext>
            </Container>
        </section>
        </>
    )
}

export default HanoiGame;

// Egy két szabály!
// Mindig vizsgálja melyik a felső mert csak azt lehet megfogni (valami dragable beállítás)
// Nyilván számozva van mindegyik és az alapján rá lehet e rakni vagy nem 
// align-self: start;
//<button type="button" className="btn btn-primary btn-sm"><AiOutlinePlusCircle/></button>
//                        <AiOutlinePlusCircle/>
//style={{'width':'50%'}}
//style={{'zIndex':-10}}
//                            <div className="d-flex  flex-column mt-auto p-2">          
//<HanoiTowerPole className='justify-content-start'/>  
// <HanoiTowerPole className='justify-content-start'/>
// 
//   {provided.placeholder}