//import { Link } from 'react-router-dom'
//import { Container, Navbar } from 'react-bootstrap'
import { CustNavbar } from '../components/CustNavbar';
import { Introduction } from '../components/Introduction';
import { ImgBoxes } from '../components/ImgBoxes';

function StartPage (): JSX.Element{

    return(
        <>
            <CustNavbar/>
            <Introduction/>
            <ImgBoxes/>
        </>
    )
}

export default StartPage;
//value='0'

//   <div>
//<div>Hello User</div>
//<div><Link to="/hanoigame">HanoiGAME</Link></div>

//</div>