import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";
import { RootState } from "../store";

export function CustNavbar(): JSX.Element { 
    const {isSubPage, navSubTitle} = useSelector((state: RootState) => state.gui)
    return(
        <Navbar bg="dark" variant="dark"
        sticky="top" expand="sm" className="navbar-dark py-3" collapseOnSelect>
            <Container>
                <Nav.Link  className="navbar-brand" href="/">BGNetwork</Nav.Link>    
                {isSubPage ? <Nav.Item className="navbar-brand" >/</Nav.Item>: null}
                <Nav.Item className="navbar-brand" >{navSubTitle}</Nav.Item>

                <Navbar.Toggle className="coloring" />
                <Navbar.Collapse>
                <Nav className="ms-auto">
                <NavDropdown title="WebCrawling">
                    <NavDropdown.Item href="/webcrawler">WebCrawler</NavDropdown.Item>
                    <NavDropdown.Item href="/realestates">Real Estates</NavDropdown.Item>
                    <NavDropdown.Item href="/googlemaproutes">Gmaps</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="CV">
                    <NavDropdown.Item href="/">Read about Me</NavDropdown.Item>
                    <NavDropdown.Item href="/cvcreator">CV creator</NavDropdown.Item>
                    <NavDropdown.Item href="/kafkachat">KafkaChat</NavDropdown.Item>
                </NavDropdown>
                
                <NavDropdown title="Games">
                    <NavDropdown.Item href="/hanoigame">HanoiGame</NavDropdown.Item>
                    <NavDropdown.Item href="/hanoicheatsheet">HanoiCheatSheet</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#products/promo">Test</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                </Navbar.Collapse>
            </Container>
      </Navbar>
      
    )
  

}