import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayRouteQuery } from 'slices/GoogleMapRoutesAction';
import { RootState } from 'store';
import { CustNavbar } from '../components/CustNavbar';


function GoogleMapRoutes (): JSX.Element{
    const [startPoint, setStartPoint] = useState<string>('szada, hungary')
    const [endPoint, setEndPoint] = useState<string>('1139 Tahi utca 76, budapest, hungary')
    const {travelTime, startLocation, endLocation} = useSelector((state: RootState) => state.googleMapRoutes)
    const dispatch = useDispatch()
    return(
        <>
            <CustNavbar/>
            <div style={{'display':'flex', 'flexDirection':'column', 'alignContent':'center'}}>
                <h2 style={{'margin':'0px 0px 5px 0px'}}>Calculate Route</h2>
                <div id='SelectionContainer' style={{'display':'flex', 'flexDirection':'row', 'justifyContent':'center'}}>
                    <div id='StartPointContainer' style={{'display':'flex', 'flexDirection':'column', 'alignContent':'center'}}>
                        <h4>Start Point</h4>
                        <select id="startPointSelect" onChange={e => {setStartPoint(e.target.value)}} value = {startPoint}>
                            <option value="szada, hungary">Szada</option>
                            <option value="1139 Tahi utca 76, budapest, hungary">Budapest</option>
                            <option value="Pomáz, hungary">Pomáz</option>
                        </select>          
                    </div>
                    <div id='EndPointContainer' style={{'display':'flex', 'flexDirection':'column', 'alignContent':'center'}}>
                        <h4>End Point</h4>
                            <select id="endPointSelect" onChange={e => {setEndPoint(e.target.value)}} value = {endPoint}>
                                <option value="szada, hungary">Szada</option>
                                <option value="1139 Tahi utca 76, budapest, hungary">Budapest</option>
                                <option value="Pomáz, hungary">Pomáz</option>
                            </select>          
                    </div>

                        <button style={{'display':'flex','height': '50%','alignSelf':'flex-end','border':'0px solid black','borderRadius': '5px'}} onClick={() => {dispatch(displayRouteQuery({startLocation: startPoint,endLocation: endPoint}))}}>Send Request</button>
  

                </div>

                <div id='TimeResultsContainer' style={{'margin':'5px 0px 0px 0px'}}>
                        <p>The travel time is {travelTime} from {startLocation} to {endLocation} !!</p>

                </div>
                   

            </div>
        </>
    )
}

export default GoogleMapRoutes;