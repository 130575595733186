import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { closeRealEstateDetails, getRealEstateDetails, getRealEstates } from "./RealEstateAction"


export interface ingatlancomPage {
    link: string,
    name: string,
    type: string,
    subtype: string,
    price: number,
    size: number,
    lotSize: string,
    gardenSize: string,
    numberOfRooms: number,
    propertyStatus: string,
    buildYear: string,
    comfort: string,
    energyLevel: string,
    level: string,
    hasBasement: Boolean,
    maxLevel: string,
    elevator: string,
    innerHeight: string,
    heatingType: string,
    airCondition: Boolean,
    overhead: string,
    accessible: string,
    bathroomToilet: string,
    orientation: string,
    view: string,
    balconySize: string,
    gardenConnection: string,
    attic: string,
    parking: string,
    isGreenHome: Boolean,
    longDescription:string,
    photos:Object,
    sellerName: string,
    sellerPhone: string

}
export interface PayloadRealEstateState  {
    _id: string,
    link: string,
    name: string,
    type: string,
    subtype: string,
    price: number,
    size: number,
    lotSize: string,
    gardenSize: string,
    numberOfRooms: number,
    propertyStatus: string,
    buildYear: string,
    comfort: string,
    energyLevel: string,
    level: string,
    hasBasement: Boolean,
    maxLevel: string,
    elevator: Boolean,
    innerHeight: string,
    heatingType: string,
    airCondition: Boolean,
    overhead: string,
    accessible: Boolean,
    bathroomToilet: string,
    orientation: string,
    view: string,
    balconySize: string,
    gardenConnection: string,
    attic: string,
    parking: string,
    isGreenHome: Boolean,
    longDescription:string,
    photos: [{urls: {
        ['1920x1080']: string,
        ['800x600']: string,
        ['228x171']: string,
        ['80x60']: string

    }}],
    sellerName: string,
    sellerPhone: string

}
export interface StatePhotoType {
    urls:{
        size1920x1080: string,
        size800x600: string,
        size228x171: string,
        size80x60: string
    }    
}

export interface RealEstateInterface  {
    _id: string,
    link: string,
    name: string,
    type: string,
    subtype: string,
    price: number,
    size: number,
    lotSize: string,
    gardenSize: string,
    numberOfRooms: number,
    propertyStatus: string,
    buildYear: string,
    comfort: string,
    energyLevel: string,
    level: string,
    hasBasement: Boolean,
    maxLevel: string,
    elevator: Boolean,
    innerHeight: string,
    heatingType: string,
    airCondition: Boolean,
    overhead: string,
    accessible: Boolean,
    bathroomToilet: string,
    orientation: string,
    view: string,
    balconySize: string,
    gardenConnection: string,
    attic: string,
    parking: string,
    isGreenHome: Boolean,
    longDescription:string,
    photos: Array<StatePhotoType>,
    sellerName: string,
    sellerPhone: string

}
export interface Payload  {
    realEstates: Array<PayloadRealEstateState>
}

export interface RealEstateState {
    realEstateArray:Array<RealEstateInterface>,
    detailedVisibility: string,
    detailedRealEstate:RealEstateInterface
}


export const initialState: RealEstateState= {realEstateArray: [{
        _id:"",link:"",name:"",type: "",
        subtype:"",price:0,size:0,lotSize:"",gardenSize:"",numberOfRooms:0,propertyStatus:"",buildYear:"",comfort:"",
        energyLevel:"",level:"",hasBasement:false,maxLevel:"",elevator:false,innerHeight:"",heatingType:"",airCondition:false,
        overhead:"",accessible:false,bathroomToilet:"",orientation:"",view:"",balconySize:"",gardenConnection:"",attic:"",
        parking:"",isGreenHome:false,longDescription:"",photos: [{urls: {size1920x1080:"",size800x600:"",size228x171:"",size80x60:""}}],sellerName:"",sellerPhone:""
}],
detailedVisibility:"display:none;",
detailedRealEstate:{
    _id:"",link:"",name:"",type: "",subtype:"",price:0,size:0,lotSize:"",gardenSize:"",numberOfRooms:0,propertyStatus:"",buildYear:"",comfort:"",
    energyLevel:"",level:"",hasBasement:false,maxLevel:"",elevator:false,innerHeight:"",heatingType:"",airCondition:false,
    overhead:"",accessible:false,bathroomToilet:"",orientation:"",view:"",balconySize:"",gardenConnection:"",attic:"",
    parking:"",isGreenHome:false,longDescription:"",photos: [{urls: {size1920x1080:"",size800x600:"",size228x171:"",size80x60:""}}],sellerName:"",sellerPhone:""
}}

const RealEstateSlice = createSlice({
    name: "realestate/getData",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(getRealEstates.fulfilled, (state, action: PayloadAction<Payload>) => {

            const stateArray: Array<RealEstateInterface> = []
            action.payload.realEstates.forEach((realEstate,index) => {
                
                const photosArray: Array<StatePhotoType> = []
                realEstate.photos.forEach((realEstatePhoto)=>{
                    const photosObject = {urls: {
                        size1920x1080:realEstatePhoto.urls['1920x1080'],size800x600:realEstatePhoto.urls['800x600'],size228x171:realEstatePhoto.urls['228x171'],size80x60:realEstatePhoto.urls['80x60']
                    }}
    
                    photosArray.push(photosObject)
                                      
                })


                const realEstateObject: RealEstateInterface ={
                    _id:realEstate._id,
                    link:realEstate.link,
                    name:realEstate.name,
                    type: realEstate.type,
                    subtype: realEstate.subtype,
                    price:realEstate.price,
                    size:realEstate.size,
                    lotSize: realEstate.lotSize,
                    gardenSize: realEstate.gardenSize,
                    numberOfRooms:realEstate.numberOfRooms,
                    propertyStatus:realEstate.propertyStatus,
                    buildYear:realEstate.buildYear,
                    comfort:realEstate.comfort,
                    energyLevel:realEstate.energyLevel,
                    level:realEstate.level,
                    hasBasement: realEstate.hasBasement,
                    maxLevel:realEstate.maxLevel,
                    elevator:realEstate.elevator,
                    innerHeight:realEstate.innerHeight,
                    heatingType:realEstate.heatingType,
                    airCondition:realEstate.airCondition,
                    overhead:realEstate.overhead,
                    accessible:realEstate.accessible,
                    bathroomToilet:realEstate.bathroomToilet,
                    orientation:realEstate.orientation,
                    view:realEstate.view,
                    balconySize:realEstate.balconySize,
                    gardenConnection:realEstate.gardenConnection,
                    attic:realEstate.attic,
                    parking:realEstate.parking,
                    isGreenHome: realEstate.isGreenHome,
                    longDescription:realEstate.longDescription,
                    photos: photosArray,
                    sellerName:realEstate.sellerName,
                    sellerPhone:realEstate.sellerPhone
                }
                
                stateArray.push(realEstateObject)


            });
            
            Object.assign(state.realEstateArray,stateArray);
            //console.log(state.realEstateArray)

            
        })
        builder.addCase(getRealEstateDetails, (state, action) => {
            state.detailedVisibility = ""
            const indexOfRealEstate = state.realEstateArray.findIndex(realEstate => {
                return realEstate._id === action.payload.id;
              });
            
            Object.assign(state.detailedRealEstate,state.realEstateArray[indexOfRealEstate])
        })

        builder.addCase(closeRealEstateDetails, (state, action) => {
            state.detailedVisibility = "display: none;"
            
        })
       
       
    }
})

export const { reducer, actions } = RealEstateSlice

