import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { CustNavbar } from "../components/CustNavbar"
import { CVCreatorGrid } from "../components/CVCreatorGrid"
import { CVFillSpace } from "../components/layout/CVCreatorComponents"
import { setNavbarTitleByURL } from "../slices/GuiAction"
import { CVCreatirGridSetter } from "../components/CVCreatorGridSetter"
import  TargetLayer  from "../components/Layer"


function CVCreator (): JSX.Element{
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setNavbarTitleByURL(
            {
                isSubPage: true,
                URLPath: window.location.pathname
            }))
      }, [])


    return(
        <>
        <CustNavbar />
        <TargetLayer/>
        <CVFillSpace> 
        <CVCreatorGrid/>
        </CVFillSpace> 
        </>
        
    )
}


export default CVCreator;