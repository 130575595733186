import ButtonAppBar from "components/ButtonAppBar"
import BasicTabs from "components/MuiTabs"


function KafkaChat(): JSX.Element {


return(
    <>
        <ButtonAppBar/>
        <BasicTabs/>
    </>

)
    
}

export default KafkaChat