import {  Row, Col } from "react-bootstrap"
import {  Button } from 'grommet'
import { Add } from 'grommet-icons'
import  CVCreatorGridProps  from "interfaces/CVCreator/cvCreatorGridProps"
import  gridChild  from "interfaces/CVCreator/gridChild"
import  rowLayout  from "interfaces/CVCreator/rowLayout"
import { useSelector } from "react-redux"
import { ExampleJSX } from "components/exampleJSX"
import { RootState } from "store"





//Use selectort akarok a col width-hez


const gridColHelper = ({rowId,numberOfCols} :rowLayout, jsxElements?: Array<gridChild>) => {
    let colArray: Array<JSX.Element> = []
    
    for (let index = 0; index < numberOfCols; index++) {
        let passedChildren: Array<JSX.Element> = []
        jsxElements?.forEach((e,i) => {
            if(rowId === e.positionInGrid[0] && index === e.positionInGrid[1])
            if (e.child == "exampleJSX"){
                passedChildren.push(<ExampleJSX uniquekey={i.toString()}/>)
            }
             
        })
        colArray.push(<Col key={"col"+index.toString()} className="border border-primary rounded-1">{passedChildren}</Col>)
    //<p>Row: {row.toString()} Col: {index.toString()}    </p>
    }
    return(colArray)
}

const gridRowHelper = ({gridLayout, children} :CVCreatorGridProps) => {
    let rowArray: Array<JSX.Element> = []
    for (let index = 0; index < gridLayout.numberOfRows; index++) {
        rowArray.push(<Row key={"row"+index.toString()} style={{'minHeight':'20px'}}>{gridColHelper(gridLayout.rowLayouts[index],children)}</Row>)//
        
    }
    return(rowArray)
}

//{gridLayout}:CVCreatorGridProps
export function CVCreatorGrid( ): JSX.Element { 
    const {gridLayout, children} = useSelector((state: RootState) => state.cvGrid)

    const exampleCVGrid: CVCreatorGridProps =
        {
            gridLayout: gridLayout,
                children: children
        }

    return(
        <>
        {  
            gridRowHelper(exampleCVGrid)
        }
        </>
    )
}