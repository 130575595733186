import GoogleMapRoutes from "pages/GoogleMapRoutes"
import KafkaChat from "pages/KafkaChat"
import RealEstatePages from "pages/RealEstatePages"
import WebCrawler from "pages/WebCrawler"
import { Switch, Route } from "react-router-dom"
import CVCreator from "../pages/CVCreator"
import HanoiCheatSheet from "../pages/HanoiCheatSheet"
import HanoiGame from "../pages/HanoiGame"
import StartPage from "../pages/StartPage"
import BasicMenu from "./MuiNavbar"

export function Routes(): JSX.Element {
    return <Switch>
        <Route path="/hanoicheatsheet">
            <HanoiCheatSheet/>
        </Route>
        <Route path="/hanoigame">
            <HanoiGame/>
        </Route>
        <Route path="/cvcreator">
            <CVCreator/>
        </Route>
        <Route path="/webcrawler">
            <WebCrawler/>
        </Route>
        <Route path="/realestates">
            <RealEstatePages/>
        </Route>
        <Route path="/muitest">
            <BasicMenu/>
        </Route>
        <Route path="/kafkachat">
            <KafkaChat/>
        </Route>
        <Route path="/googlemaproutes">
            <GoogleMapRoutes/>
        </Route>
        
        <Route path="/">
            <StartPage/>
        </Route>
    </Switch>
}