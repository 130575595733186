import { createSlice } from "@reduxjs/toolkit"
import { sendMessage, updateMessageStream } from "./KafkaChatAction"

export interface ChatMessage
{
    provider: string,
    consumers: Array<string>,
    message: string

}

export interface KafkaChatState  
{
    messages: Array<ChatMessage>
}
    


export const initialState: KafkaChatState = {
    messages:[{provider:'Gergő',consumers:['Balázs'],message:'Hali, hogy vagy ?'},
    {provider:'Balázs',consumers:['Gergő'],message:'Jól'}]

}



const KafkaChatSlice = createSlice({
    name: "kafkachat",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(updateMessageStream, (state, action) => {

        })
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            
        })
       
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = KafkaChatSlice