import { ChatMessageBox, ChatMessageBoxInside, ChatMessageContent, ChatMessageSender } from "../ChatBoxLayout";

export interface ChatMessageProps {
    sender:string;
    message:string;
    messageDirection:string;
    messageColor:string;

}

function ChatMessage({sender, message, messageDirection, messageColor}: ChatMessageProps): JSX.Element{

return(
        <ChatMessageBox messageDirection={messageDirection}>
            <ChatMessageBoxInside>
                <ChatMessageSender>
                    {sender}
                </ChatMessageSender>
                <ChatMessageContent messageColor={messageColor}>
                    {message}
                </ChatMessageContent>
            </ChatMessageBoxInside>
        </ChatMessageBox> 
)

}

export default ChatMessage;