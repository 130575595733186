import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { ChatMessage } from "./KafkaChatSlice"

export const updateMessageStream = createAction<ChatMessage>('kafka/chatmessage')


export const sendMessage = createAsyncThunk(
    "kafka/chatmessage",
    async (arg: string, thunkAPI) => {
        console.log("Küld")
        const response = await fetch('http://bgdevelopment.hu/api/kafkachat/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify({sendmessage: arg, topic:'test', sender:'Gergő'})
    
        })

        return await response.json()
        //return ['cool']
    }
)