import React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom"
import { InnerApp } from './components/InnerApp';

function App() {

  return <BrowserRouter>
        <InnerApp />
    </BrowserRouter>
}


export default App;
