import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readChatMessage } from "services/kafkaService";
import { sendMessage } from "slices/KafkaChatAction";
import { RootState } from "store";
import { ChatContainer,TextTable, ScrollContainer, ChatTable } from "./ChatBoxLayout";
import ChatMessage from "./components/ChatMessage";


export function ChatBox(): JSX.Element{
    const {messages} = useSelector((state: RootState) => state.kafkaChat)
    const [messageContent,setMessageContent] = useState("")
    const dispatch = useDispatch()
    return(
        <>
     <ChatContainer>
        <ScrollContainer>
            <TextTable> 
                {messages.map(message => {
                    var messageColor;
                    var messageDirection;
                    if (message.provider === "Gergő")
                    {
                          messageColor = '#6495ED'
                          messageDirection = 'end'

                    }
                    else
                    {
                        messageColor = '#E9967A'
                        messageDirection = 'start'
                    }
                    return (<ChatMessage sender={message.provider} message={message.message} messageDirection={messageDirection} messageColor={messageColor}/>)})}
   

            </TextTable>
        </ScrollContainer>
        <ChatTable onChange={e => {setMessageContent(e.target.value)}}/>
        <button onClick={() =>{dispatch(sendMessage(messageContent))}} style={{'backgroundColor':'#6495ED','width' : '150px', 'height' : '20px', 'border': '0px solid black', 'borderRadius': '5px'}}>Send Message</button>
        <button onClick={() =>{readChatMessage('test')}} style={{'margin':'10px 0px 0px 0px','backgroundColor':'#6495ED','width' : '150px', 'height' : '20px', 'border': '0px solid black', 'borderRadius': '5px'}}>Show Log</button>
     </ChatContainer>
     
     </>
  
    )
}