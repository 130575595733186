import React, { LegacyRef, MutableRefObject } from 'react'
import { Box, Button, CheckBox, Grid, Grommet, Layer, LayerPositionType, Select } from 'grommet'
import { grommet } from 'grommet/themes'
import { CVFillSpace, LayerDropButton } from './layout/CVCreatorComponents'
import { CVCreatirGridSetter } from './CVCreatorGridSetter'
import { CVCreatorGrid } from './CVCreatorGrid'
import { BiChevronDown } from "react-icons/bi"
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

//EZT ÁT KELL ÍRNI ÁLTALÁNOSRA ÚGY HOGY CHILD PROPKÉNT KAPJA A BELSEJÉT!!!

function TargetLayer (): JSX.Element{
  const position: LayerPositionType = 'top'
  const [open, setOpen] = React.useState<boolean>()
  const [gutter, setGutter] = React.useState('small')
  const [modal, setModal] = React.useState(true)
  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'))
    return undefined
  }, [gutter])
  const ref = React.useRef<any>(null) //HTMLDivElement
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(undefined)

  return (
    <>
      <LayerDropButton onClick={onOpen} >
      <BiChevronDown />
      </LayerDropButton>

      {open && (
        <Layer
          modal={modal}
          position={position}
          target={ref.current}
          onClickOutside={onClose}
          onEsc={onClose}
          style={{"width":"1200px"}}
        >

        <CVFillSpace>     
            <Box height="small" />       
            <CVCreatirGridSetter/>
            <Button label="Close" onClick={onClose} />
        </CVFillSpace>

            
        </Layer>
      )}
      </>
  )
}
export default TargetLayer

//<AiOutlineMinusCircle style={{'width':'60%','height':'60%'}}/>
//BiChevronDown
     // {/* Adding Box as placeholder to demo how Layer scrolls
     //   with target */}
     //   <Box height="large" />

     //style={{'width':'10%','height':'10%'}}
     //, "height":"600px"