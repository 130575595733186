import { createSlice } from "@reduxjs/toolkit"
import { Button } from "grommet"
import CVCreatorGridProps from "interfaces/CVCreator/cvCreatorGridProps"
import titleCaseWord from "../services/SharedFunctions"
import { setCVGridDimensions, setCVGridNumberOfRows } from "./CVGridAction"
import { setNavbarTitleByURL } from "./GuiAction"





const initialState: CVCreatorGridProps =
{
    gridLayout:{
        numberOfRows: 3,
        rowLayouts:[{rowId: 0,numberOfCols: 2, colLayouts:[{colId:0},{colId:1}]},{rowId: 1,numberOfCols: 4, colLayouts:[{colId:0},{colId:1},{colId:2},{colId:3}]},{rowId: 2,numberOfCols: 1, colLayouts:[{colId:0,colProperty:""}]}]
        

        },
        children:[{positionInGrid:[1,0],child: "exampleJSX"},{positionInGrid:[2,0],child: "exampleJSX"},{positionInGrid:[2,0],child: "exampleJSX"}] 
}

const CVGridSlice = createSlice({
    name: "cvgrid",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(setCVGridDimensions, (state, action) => {
            Object.assign(state.gridLayout,action.payload.gridLayout);
            Object.assign(state.gridLayout,action.payload.gridLayout);
           // https://stackoverflow.com/questions/58850699/useselector-not-updating-when-store-has-changed-in-reducer-reactjs-redux

        })
        builder.addCase(setCVGridNumberOfRows, (state,action) =>{
            state.gridLayout.numberOfRows = action.payload
        })
       
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = CVGridSlice
