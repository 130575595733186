//src/index.ts
import { Kafka } from 'kafkajs';
import { v4 as uuidv4 } from 'uuid';



export async function readChatMessage(topic:string) {
    
    const kafkaBroker: string =  process.env.KAFKABROKER || '127.0.0.1:9092'
    const kafka = new Kafka({
    clientId: topic,
    brokers: [kafkaBroker]
    });

    const consumer = kafka.consumer({ groupId: uuidv4() });

    consumer.connect().then(() =>
    consumer.subscribe({topic: topic}).then(() =>
      consumer.run({
        eachMessage: async ({topic, partition, message}) => {
          const formattedValue = JSON.parse((message.value as Buffer).toString()); // everything comes as a buffer
          console.log(`${formattedValue.sender}: ${formattedValue.message}`)// print the message
        },
      })
    )
  );


}




