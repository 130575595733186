import { CustNavbar } from "components/CustNavbar"
import { ImageCards } from "components/ImageCards"
import { CardGrid, RealEstatePageContainer, RealEstateReadBox, CloseButton, DetailedPageHeaderHolder, SearchInputBox, PictureBox, RealEstateImg } from "components/layout/RealEstateComponents"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { setNavbarTitleByURL } from "slices/GuiAction"
import { getRealEstates, closeRealEstateDetails } from "slices/RealEstateAction"
import { RootState } from '../store'
import { GrClose } from "react-icons/gr";

function RealEstatePages():JSX.Element {
    const dispatch = useDispatch()
    const [searchBox, setSearchBox] = useState("")
    const realEstates = useSelector((state: RootState) => state.realEstate)


    useEffect(() => {
        dispatch(setNavbarTitleByURL(
            {
                isSubPage: true,
                URLPath: window.location.pathname
                
            })
            )

      }, [])

      

    return(
        <>
        <CustNavbar/>
        <Row>
            <Col>
            <div className="container">
                <SearchInputBox type="text" onChange={e => setSearchBox(e.target.value)} placeholder="Enter URL" name="urlscrape" value={searchBox} required />
            </div>
            <div className="container">
                <button onClick={() => dispatch(getRealEstates(searchBox))}>Send Query</button> 
            </div>
            </Col>

        </Row>
        <RealEstatePageContainer>
        <CardGrid>{
            realEstates.realEstateArray.map((realEstate) =>{
                
                let photoUrl = ""
                console.log(realEstate.photos)
                for (let index = 0; index < realEstate.photos.length; index++) {
                    if (realEstate.photos[index].urls.size800x600 != undefined)
                    {
                    photoUrl = realEstate.photos[index].urls.size800x600;
                    break;
                    }
                    
                }
               return <ImageCards name={realEstate.name} photoUrl={photoUrl} price={realEstate.price} size={realEstate.size} id={realEstate._id}/>
            })
            
        }
        </CardGrid>
        <RealEstateReadBox visibility={realEstates.detailedVisibility}>
        <DetailedPageHeaderHolder>
        <h1>{realEstates.detailedRealEstate.name}</h1>
        <CloseButton onClick={() => dispatch(closeRealEstateDetails())}><GrClose/> </CloseButton>
        </DetailedPageHeaderHolder>
        <Row>
            <Col>Ár: {realEstates.detailedRealEstate.price} Ft</Col>
            <Col>Méret: {realEstates.detailedRealEstate.size} m<sup>2</sup></Col>
        </Row>
        <Row>
            <Col>Teljes: {realEstates.detailedRealEstate.lotSize} Ft</Col>
            <Col>Kert: {realEstates.detailedRealEstate.gardenSize} m<sup>2</sup></Col>
        </Row>
        <Row>
            <Col>Szobaszám: {realEstates.detailedRealEstate.numberOfRooms}</Col>
            <Col>Szint: {realEstates.detailedRealEstate.level}</Col>
        </Row>
        <Row>
            <Col>Tájolás: {realEstates.detailedRealEstate.orientation}</Col>
            <Col>Kilátás: {realEstates.detailedRealEstate.view}</Col>
        </Row>
        <Row>
            <Col>Ingatlanos: {realEstates.detailedRealEstate.sellerName}</Col>
            <Col>Telszám: {realEstates.detailedRealEstate.sellerPhone}</Col>
        </Row>
        <div id='InputForm'>
            <input id='EditWholeInput'></input>
            <input id='ConcatInput'></input>
            <button id='ApplyChanges'></button>
        </div>
        <PictureBox>
            {
            realEstates.detailedRealEstate.photos.map((photos) => { return <RealEstateImg src={photos.urls.size800x600}/>})    
            }
        <RealEstateImg src=""/>
        </PictureBox>


        <p>{realEstates.detailedRealEstate.longDescription}</p>

        </RealEstateReadBox>
        </RealEstatePageContainer>
        </>
    )
    
}


export default RealEstatePages