import { useEffect, useState } from 'react'
import { CustNavbar } from '../components/CustNavbar'
import { ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getHanoiCheatSheet } from '../slices/HanoitowerAction'
import { RootState } from '../store'
import { setNavbarTitleByURL } from '../slices/GuiAction'
//import { useMatch } from "@reach/router"

function HanoiCheatSheet (): JSX.Element{
    const [towerlvl, setTowerlvl] = useState<string>("")
    const dispatch = useDispatch()
    const { cheatSheet } = useSelector((state: RootState) => state.hanoiTower)
    useEffect(() => {
      dispatch(setNavbarTitleByURL(
          {
              isSubPage: true,
              URLPath: window.location.pathname
          }))
    }, [])
    //const match = window.location.pathname
    //console.log(match)
    return(
        
        <><CustNavbar /><div>
            <div className="container">
                <input type="text" onChange={e => setTowerlvl(e.target.value)} placeholder="Enter Number" name="lvlnumber" required />
            </div>
            <div className="container">
                <button onClick={() => dispatch(getHanoiCheatSheet(+towerlvl))}>Calculate</button> 
            </div>
        </div>
        <section>
        <ListGroup className="text-center p-5">
            {cheatSheet.map((solveSteps, index) => { return (
            <ListGroup.Item id={index.toString()}>{solveSteps}</ListGroup.Item>)
            })}            
        </ListGroup>
        </section>
        </>
    )
}

export default HanoiCheatSheet;
// TERVEK 
// - írja ki kártyákon, a lépéseket
// ez egy react state legyen
// csinálni egy másik oldalt ahol játszani lehet
//CalculateButton(towerlvl)
//{cheatSheet.map(solveSteps => {
//    <ListGroup.Item>{solveSteps}</ListGroup.Item>
//    })}