import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { displayRouteQuery } from "./GoogleMapRoutesAction"

export interface ChatMessage
{
    provider: string,
    consumers: Array<string>,
    message: string

}

export interface GoogleMapRoutesState  
{
    startLocation: string,
    endLocation: string,
    travelTime: string
}
    


export const initialState: GoogleMapRoutesState = {
    startLocation: '',
    endLocation: '',
    travelTime: ''

}



const GoogleMapRoutesSlice = createSlice({
    name: "googlemaproutes",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(displayRouteQuery.fulfilled, (state, action: PayloadAction<GoogleMapRoutesState>) => {
            console.log(action.payload.travelTime)
            state.travelTime = action.payload.travelTime
            state.startLocation = action.payload.startLocation
            state.endLocation =  action.payload.endLocation
        })
       
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = GoogleMapRoutesSlice