import { createAsyncThunk } from "@reduxjs/toolkit"
//import {} from "fetch-absolute"
export interface GoogleMapRoutesInput {
    startLocation: string,
    endLocation: string
}
export const displayRouteQuery = createAsyncThunk(
    "google/displayRoute",
    async (args: GoogleMapRoutesInput, thunkAPI) => {
        console.log("Küld")
        const response = await fetch('http://bgdevelopment.hu/:3001/api/googlemaproutes/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify(args)
    
        })
        return await response.json()
        //return ['cool']
    }
)