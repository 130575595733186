import styled from "styled-components"

export const CardGrid = styled.div`
display: flex;
flex-direction: row;
width:100%;
justify-content:center;
max-width: 1200px;
flex-wrap: wrap;
`

export const RealEstatePageContainer = styled.section`
display: flex;
flex-direction: row;
width:100%;
justify-content:center;
`

interface RealEstateReadBoxProps{
    visibility: string
}

export const RealEstateReadBox = styled.div<RealEstateReadBoxProps>`
display: flex;
flex-direction: column;
width: 1400px;
${(props) => props.visibility}
`

export const CloseButton = styled.button`
background: transparent;
border: none !important;
`
export const DetailedPageHeaderHolder = styled.div`
display: flex;
flex-direction: row;
justify-content:space-between;
`

export const SearchInputBox = styled.input`
display: flex;
border-radius: 40px;
max-width: 90%;
`

export const ShowMoreButton = styled.button`
background-color: #11425D;
color: #B3A53A;
border: 0px solid #7993A1;
border-radius: 5px;
box-shadow: 0px 4px 20px #7993A1;
transition: all 0.5s ease;
width: 190px;
height: 70px;
&:hover {
    width: 195x;
    height: 72px;
}
`

export const PictureBox = styled.div`
display: flex;
flex-wrap: wrap;
justify-content:center;


`

export const RealEstateImg = styled.img`
width:300px;
height:225px;
transition: all 0.5s ease;
margin:10px;
&:hover {
   transform: scale(1.1)
}
`