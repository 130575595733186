import { createAsyncThunk, createAction } from "@reduxjs/toolkit"

export const getRealEstates = createAsyncThunk(
    "realestates/getRealEstates",
    async (arg: string, thunkAPI) => {
        console.log("Küld2")
        const response = await fetch('http://bgdevelopment.hu/api/realestates/get', {
    
            method: "POST",
            
            headers: {"Content-Type": "application/json",
            'Accept': 'application/json'},
            
            body: JSON.stringify({query: arg})
    
        })
        //await console.log(response.json())
        return await response.json()
        //return ['cool']
    }


)

export interface getRealEstateDetailsProps{
id: string
}

export const getRealEstateDetails = createAction<getRealEstateDetailsProps>('realestate/detailed')

export const closeRealEstateDetails = createAction('realestate/close')