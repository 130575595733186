import React, { useEffect, useState } from 'react'
import { Box, Grommet, Select, grommet, BoxProps, TextProps } from 'grommet'
import { BackgroundType } from 'grommet/utils';
import { SelectDropDown } from '../layout/FormComponents';

interface SelectBoxProps{
  numberOfElements: number,
  selectedElement?: number,
  onChange?: (...args: any[]) => void
}



export const SelectBox = ({numberOfElements, onChange}: SelectBoxProps): JSX.Element => {

  useEffect(() => {
    setRowList(listOfNumberOfElements)
  }, [numberOfElements])


  const listOfNumberOfElements = Array.from(Array(numberOfElements + 1).keys())
  listOfNumberOfElements.shift()



  const defaultElementList: Array<number> = listOfNumberOfElements
  const [elementList,setRowList] = useState<Array<number>>(defaultElementList)
  
  const options = elementList.map((element,index) => {
    return(
      <option key={index.toString()}>{element.toString()}</option>
    )

  })

  return(
    <SelectDropDown onChange = {(e) => {if (typeof onChange !== "undefined"){onChange(e)}}}>
      {options}
    </SelectDropDown>  
  )

}

