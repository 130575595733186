import styled from "styled-components"

export interface ChatMessageBoxProps{
    messageDirection: string
}

export interface ChatMessageContentProps{
    messageColor: string

}

export const ChatContainer = styled.div`
display: flex;
flex-direction: column;
align-content: center;
max-height: 400px;
`
export const ScrollContainer = styled.div`
overflow-y:scroll;
border: 1px solid black;
border-radius: 5px;
max-height: 400px;
width:425px;
&::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
}
&::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 5px;
}

`
export const TextTable = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-end;
width:400px;
`
export const ChatTable = styled.input`
height:20%;
width:425px;
border: 1px solid grey;
border-radius: 5px;
margin: 2px 0px 0px 0px;
`

export const ChatMessageBox = styled.div<ChatMessageBoxProps>`
display: flex;
justify-content:${props => props.messageDirection};
background-color: transparent;
width: 100%;
height: 40px;
`

export const ChatMessageBoxInside = styled.div`
display: flex;
flex-direction: column;
background-color: transparent;
`

export const ChatMessageSender = styled.div`
display: flex;
font-size: 10px;
line-height: 10px;
color:grey;
margin: 0px 0px 0px 5px;
`
export const ChatMessageContent = styled.div<ChatMessageContentProps>`
display: flex;
border: 0px solid  ${props => props.messageColor};
border-radius: 10px;
background-color: ${props => props.messageColor} !important;
color: white;
font-size: 10px;
padding: 5px;
`