import {Card} from "react-bootstrap"
import { useDispatch } from "react-redux"
import { getRealEstateDetails } from "slices/RealEstateAction"
import { ShowMoreButton } from "./layout/RealEstateComponents"
interface ImageCardsProps {
    name: string,
    photoUrl: string,
    size: number,
    price: number,
    id: string
}

export function ImageCards({name, photoUrl, price, size, id}: ImageCardsProps): JSX.Element {
    const dispatch = useDispatch()

    return(
        <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={photoUrl} />
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>
                {price} Millió Ft<br/>
                {size} m<sup>2</sup><br/>
                </Card.Text>
                <ShowMoreButton onClick={() => dispatch(getRealEstateDetails({id:id}))}>Show Realestate</ShowMoreButton>
            </Card.Body>
        </Card>
    )
}