import { createSlice } from "@reduxjs/toolkit"
import titleCaseWord from "../services/SharedFunctions"
import { setNavbarTitleByURL } from "./GuiAction"




export interface GuiState  
{
    isSubPage: boolean,
    URLPath: string,
    navSubTitle: string
}
    


export const initialState: GuiState = {
    isSubPage: false,
    URLPath: "",
    navSubTitle:""

}

const GuiSlice = createSlice({
    name: "gui",//schedule
    initialState: initialState,
    reducers: {
        //setViewSchedule(state, action: PayloadAction<Schedule>) { state.viewSchedule = action.payload }
    },
    extraReducers: builder => {
        builder.addCase(setNavbarTitleByURL, (state, action) => {
            state.isSubPage = action.payload.isSubPage
            state.navSubTitle = titleCaseWord(action.payload.URLPath.substring(1))
        })
       
    }
})

//export const { setViewSchedule } = ScheduleSlice.actions
export const { reducer, actions } = GuiSlice
